[data-dapp-kit].ConnectionStatus_container__nckm2d0, [data-dapp-kit] .ConnectionStatus_container__nckm2d0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
[data-dapp-kit].ConnectionStatus_walletIcon__nckm2d1, [data-dapp-kit] .ConnectionStatus_walletIcon__nckm2d1 {
  -o-object-fit: cover;
     object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: var(--dapp-kit-radii-large);
}
[data-dapp-kit].ConnectionStatus_title__nckm2d2, [data-dapp-kit] .ConnectionStatus_title__nckm2d2 {
  margin-top: 12px;
}
[data-dapp-kit].ConnectionStatus_connectionStatus__nckm2d3, [data-dapp-kit] .ConnectionStatus_connectionStatus__nckm2d3 {
  margin-top: 4px;
}
[data-dapp-kit].ConnectionStatus_retryButtonContainer__nckm2d4, [data-dapp-kit] .ConnectionStatus_retryButtonContainer__nckm2d4 {
  position: absolute;
  bottom: 20px;
  right: 20px;
}