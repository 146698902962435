[data-dapp-kit].WhatIsAWallet_container__1ktpkq90, [data-dapp-kit] .WhatIsAWallet_container__1ktpkq90 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
[data-dapp-kit].WhatIsAWallet_content__1ktpkq91, [data-dapp-kit] .WhatIsAWallet_content__1ktpkq91 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 20px;
  padding: 40px;
}