[data-dapp-kit].AccountDropdownMenu_connectedAccount__div2ql0, [data-dapp-kit] .AccountDropdownMenu_connectedAccount__div2ql0 {
  gap: 8px;
}
[data-dapp-kit].AccountDropdownMenu_menuContainer__div2ql1, [data-dapp-kit] .AccountDropdownMenu_menuContainer__div2ql1 {
  z-index: 999999999;
}
[data-dapp-kit].AccountDropdownMenu_menuContent__div2ql2, [data-dapp-kit] .AccountDropdownMenu_menuContent__div2ql2 {
  display: flex;
  flex-direction: column;
  width: 180px;
  max-height: 200px;
  margin-top: 4px;
  padding: 8px;
  gap: 8px;
  border-radius: var(--dapp-kit-radii-large);
  background-color: var(--dapp-kit-backgroundColors-dropdownMenu);
}
[data-dapp-kit].AccountDropdownMenu_menuItem__div2ql3, [data-dapp-kit] .AccountDropdownMenu_menuItem__div2ql3 {
  padding: 8px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  outline: none;
  display: flex;
  align-items: center;
  border-radius: var(--dapp-kit-radii-large);
}
[data-dapp-kit].AccountDropdownMenu_menuItem__div2ql3[data-highlighted], [data-dapp-kit] .AccountDropdownMenu_menuItem__div2ql3[data-highlighted] {
  background-color: var(--dapp-kit-backgroundColors-primaryButton);
}
[data-dapp-kit].AccountDropdownMenu_switchAccountMenuItem__div2ql4, [data-dapp-kit] .AccountDropdownMenu_switchAccountMenuItem__div2ql4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
[data-dapp-kit].AccountDropdownMenu_separator__div2ql5, [data-dapp-kit] .AccountDropdownMenu_separator__div2ql5 {
  height: 1px;
  flex-shrink: 0;
  background-color: var(--dapp-kit-backgroundColors-dropdownMenuSeparator);
}