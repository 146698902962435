[data-dapp-kit].Button_buttonVariants__x1s81q0, [data-dapp-kit] .Button_buttonVariants__x1s81q0 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--dapp-kit-fontWeights-medium);
}
[data-dapp-kit].Button_buttonVariants__x1s81q0:disabled, [data-dapp-kit] .Button_buttonVariants__x1s81q0:disabled {
  opacity: 0.5;
}
[data-dapp-kit].Button_buttonVariants_variant_primary__x1s81q1, [data-dapp-kit] .Button_buttonVariants_variant_primary__x1s81q1 {
  background-color: var(--dapp-kit-backgroundColors-primaryButton);
  color: var(--dapp-kit-colors-primaryButton);
  box-shadow: var(--dapp-kit-shadows-primaryButton);
}
[data-dapp-kit].Button_buttonVariants_variant_primary__x1s81q1:hover, [data-dapp-kit] .Button_buttonVariants_variant_primary__x1s81q1:hover {
  background-color: var(--dapp-kit-backgroundColors-primaryButtonHover);
}
[data-dapp-kit].Button_buttonVariants_variant_outline__x1s81q2, [data-dapp-kit] .Button_buttonVariants_variant_outline__x1s81q2 {
  border-width: 1px;
  border-style: solid;
  border-color: var(--dapp-kit-borderColors-outlineButton);
  color: var(--dapp-kit-colors-outlineButton);
}
[data-dapp-kit].Button_buttonVariants_variant_outline__x1s81q2:hover, [data-dapp-kit] .Button_buttonVariants_variant_outline__x1s81q2:hover {
  background-color: var(--dapp-kit-backgroundColors-outlineButtonHover);
}
[data-dapp-kit].Button_buttonVariants_size_md__x1s81q3, [data-dapp-kit] .Button_buttonVariants_size_md__x1s81q3 {
  border-radius: var(--dapp-kit-radii-medium);
  padding: 8px 16px;
}
[data-dapp-kit].Button_buttonVariants_size_lg__x1s81q4, [data-dapp-kit] .Button_buttonVariants_size_lg__x1s81q4 {
  border-radius: var(--dapp-kit-radii-large);
  padding: 16px 24px ;
}