[data-dapp-kit].GettingStarted_container__1fp07e10, [data-dapp-kit] .GettingStarted_container__1fp07e10 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
[data-dapp-kit].GettingStarted_content__1fp07e11, [data-dapp-kit] .GettingStarted_content__1fp07e11 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 20px;
  padding: 40px;
}
[data-dapp-kit].GettingStarted_installButtonContainer__1fp07e12, [data-dapp-kit] .GettingStarted_installButtonContainer__1fp07e12 {
  position: absolute;
  bottom: 20px;
  right: 20px;
}