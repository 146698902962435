[data-dapp-kit].Heading_headingVariants_size_sm__1aa835k1, [data-dapp-kit] .Heading_headingVariants_size_sm__1aa835k1 {
  font-size: var(--dapp-kit-fontSizes-small);
}
[data-dapp-kit].Heading_headingVariants_size_md__1aa835k2, [data-dapp-kit] .Heading_headingVariants_size_md__1aa835k2 {
  font-size: var(--dapp-kit-fontSizes-medium);
}
[data-dapp-kit].Heading_headingVariants_size_lg__1aa835k3, [data-dapp-kit] .Heading_headingVariants_size_lg__1aa835k3 {
  font-size: var(--dapp-kit-fontSizes-large);
}
[data-dapp-kit].Heading_headingVariants_size_xl__1aa835k4, [data-dapp-kit] .Heading_headingVariants_size_xl__1aa835k4 {
  font-size: var(--dapp-kit-fontSizes-xlarge);
}
[data-dapp-kit].Heading_headingVariants_weight_normal__1aa835k5, [data-dapp-kit] .Heading_headingVariants_weight_normal__1aa835k5 {
  font-weight: var(--dapp-kit-fontWeights-normal);
}
[data-dapp-kit].Heading_headingVariants_weight_bold__1aa835k6, [data-dapp-kit] .Heading_headingVariants_weight_bold__1aa835k6 {
  font-weight: var(--dapp-kit-fontWeights-bold);
}
[data-dapp-kit].Heading_headingVariants_truncate_true__1aa835k7, [data-dapp-kit] .Heading_headingVariants_truncate_true__1aa835k7 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}