[data-dapp-kit].Text_textVariants_size_sm__2bv1ur1, [data-dapp-kit] .Text_textVariants_size_sm__2bv1ur1 {
  font-size: var(--dapp-kit-fontSizes-small);
}
[data-dapp-kit].Text_textVariants_weight_normal__2bv1ur2, [data-dapp-kit] .Text_textVariants_weight_normal__2bv1ur2 {
  font-weight: var(--dapp-kit-fontWeights-normal);
}
[data-dapp-kit].Text_textVariants_weight_medium__2bv1ur3, [data-dapp-kit] .Text_textVariants_weight_medium__2bv1ur3 {
  font-weight: var(--dapp-kit-fontWeights-medium);
}
[data-dapp-kit].Text_textVariants_weight_bold__2bv1ur4, [data-dapp-kit] .Text_textVariants_weight_bold__2bv1ur4 {
  font-weight: var(--dapp-kit-fontWeights-bold);
}
[data-dapp-kit].Text_textVariants_color_muted__2bv1ur5, [data-dapp-kit] .Text_textVariants_color_muted__2bv1ur5 {
  color: var(--dapp-kit-colors-bodyMuted);
}
[data-dapp-kit].Text_textVariants_color_danger__2bv1ur6, [data-dapp-kit] .Text_textVariants_color_danger__2bv1ur6 {
  color: var(--dapp-kit-colors-bodyDanger);
}
[data-dapp-kit].Text_textVariants_mono_true__2bv1ur7, [data-dapp-kit] .Text_textVariants_mono_true__2bv1ur7 {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}