[data-dapp-kit].WalletListItem_container__1dqqtqs0, [data-dapp-kit] .WalletListItem_container__1dqqtqs0 {
  display: flex;
}
[data-dapp-kit].WalletListItem_walletItem__1dqqtqs1, [data-dapp-kit] .WalletListItem_walletItem__1dqqtqs1 {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 8px;
  gap: 8px;
  border-radius: var(--dapp-kit-radii-large);
}
[data-dapp-kit].WalletListItem_walletItem__1dqqtqs1:hover, [data-dapp-kit] .WalletListItem_walletItem__1dqqtqs1:hover {
  background-color: var(--dapp-kit-backgroundColors-walletItemHover);
}
[data-dapp-kit].WalletListItem_selectedWalletItem__1dqqtqs2, [data-dapp-kit] .WalletListItem_selectedWalletItem__1dqqtqs2 {
  background-color: var(--dapp-kit-backgroundColors-walletItemSelected);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
}
[data-dapp-kit].WalletListItem_walletIcon__1dqqtqs3, [data-dapp-kit] .WalletListItem_walletIcon__1dqqtqs3 {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: var(--dapp-kit-radii-small);
}