[data-dapp-kit].ConnectModal_overlay__gz8z90, [data-dapp-kit] .ConnectModal_overlay__gz8z90 {
  background-color: var(--dapp-kit-backgroundColors-modalOverlay);
  -webkit-backdrop-filter: var(--dapp-kit-blurs-modalOverlay);
          backdrop-filter: var(--dapp-kit-blurs-modalOverlay);
  position: fixed;
  inset: 0;
  z-index: 999999999;
}
[data-dapp-kit].ConnectModal_title__gz8z91, [data-dapp-kit] .ConnectModal_title__gz8z91 {
  padding-left: 8px;
}
[data-dapp-kit].ConnectModal_content__gz8z92, [data-dapp-kit] .ConnectModal_content__gz8z92 {
  background-color: var(--dapp-kit-backgroundColors-modalPrimary);
  border-radius: var(--dapp-kit-radii-xlarge);
  color: var(--dapp-kit-colors-body);
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  min-height: 50vh;
  max-height: 85vh;
  max-width: 700px;
}
[data-dapp-kit].ConnectModal_whatIsAWalletButton__gz8z93, [data-dapp-kit] .ConnectModal_whatIsAWalletButton__gz8z93 {
  background-color: var(--dapp-kit-backgroundColors-modalSecondary);
  padding: 16px;
}
[data-dapp-kit].ConnectModal_viewContainer__gz8z94, [data-dapp-kit] .ConnectModal_viewContainer__gz8z94 {
  display: none;
  padding: 20px;
  flex-grow: 1;
}
[data-dapp-kit].ConnectModal_selectedViewContainer__gz8z95, [data-dapp-kit] .ConnectModal_selectedViewContainer__gz8z95 {
  display: flex;
}
[data-dapp-kit].ConnectModal_backButtonContainer__gz8z96, [data-dapp-kit] .ConnectModal_backButtonContainer__gz8z96 {
  position: absolute;
  top: 20px;
  left: 20px;
}
[data-dapp-kit].ConnectModal_closeButtonContainer__gz8z97, [data-dapp-kit] .ConnectModal_closeButtonContainer__gz8z97 {
  position: absolute;
  top: 16px;
  right: 16px;
}
[data-dapp-kit].ConnectModal_walletListContent__gz8z98, [data-dapp-kit] .ConnectModal_walletListContent__gz8z98 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 20px;
  background-color: var(--dapp-kit-backgroundColors-modalPrimary);
}
[data-dapp-kit].ConnectModal_walletListContainer__gz8z99, [data-dapp-kit] .ConnectModal_walletListContainer__gz8z99 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}
[data-dapp-kit].ConnectModal_walletListContainerWithViewSelected__gz8z9a, [data-dapp-kit] .ConnectModal_walletListContainerWithViewSelected__gz8z9a {
  display: none;
}
@media screen and (min-width: 768px) {
  [data-dapp-kit].ConnectModal_content__gz8z92, [data-dapp-kit] .ConnectModal_content__gz8z92 {
    flex-direction: row;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  [data-dapp-kit].ConnectModal_whatIsAWalletButton__gz8z93, [data-dapp-kit] .ConnectModal_whatIsAWalletButton__gz8z93 {
    display: none;
  }
  [data-dapp-kit].ConnectModal_viewContainer__gz8z94, [data-dapp-kit] .ConnectModal_viewContainer__gz8z94 {
    display: flex;
  }
  [data-dapp-kit].ConnectModal_backButtonContainer__gz8z96, [data-dapp-kit] .ConnectModal_backButtonContainer__gz8z96 {
    display: none;
  }
  [data-dapp-kit].ConnectModal_walletListContent__gz8z98, [data-dapp-kit] .ConnectModal_walletListContent__gz8z98 {
    background-color: var(--dapp-kit-backgroundColors-modalSecondary);
  }
  [data-dapp-kit].ConnectModal_walletListContainer__gz8z99, [data-dapp-kit] .ConnectModal_walletListContainer__gz8z99 {
    flex-direction: row;
    flex-basis: 240px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  [data-dapp-kit].ConnectModal_walletListContainerWithViewSelected__gz8z9a, [data-dapp-kit] .ConnectModal_walletListContainerWithViewSelected__gz8z9a {
    display: flex;
  }
}